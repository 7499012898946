import react from "react";

function Parceiros(){
    return <section id="parceiros">   
        <div className="parceiros-logos">  
            <div className="container-fluid">
                <div className="row linha4">
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/3rpN2T8" target="_blank">
                            <img src="/Images/Parceiros/R31.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://api.whatsapp.com/send?phone=5581988179652" target="_blank">
                            <img src="/Images/Parceiros/Sintel.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="/parceiro-certificacao-digital" target="_blank">
                            <img src="/Images/Parceiros/Certificado.digital.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/3Pynts7" target="_blank">
                            <img src="/Images/Parceiros/Hotmart.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="#" target="_blank">
                            <img src="/Images/Parceiros/Pedro-Eurico.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/3LbdnuP" target="_blank">
                            <img src="/Images/Parceiros/Everso.png" />
                        </a>
                    </div>                   
                </div>
                <div className="row linha2">
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://www.instagram.com/nebl_planosdesaude/" target="_blank">
                            <img src="/Images/Parceiros/Nebl-cuida-de-voce.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="https://www.instagram.com/arena_society_erasme_lins/" target="_blank">
                        <img src="/Images/Parceiros/Arena-Society.png" />
                    </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="http://bit.ly/3UN1KNb" target="_blank">
                        <img src="/Images/Parceiros/Vargas.png" />
                    </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="https://www.instagram.com/nisia.vasconcelos/" target="_blank">
                        <img src="/Images/Parceiros/Nisia-planos-de-saude.png" />
                    </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="#">
                        <img src="/Images/Parceiros/potencia-solucoes.png" />
                    </a>    
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://api.whatsapp.com/send?phone=5581987165260" target="_blank">
                            <img src="/Images/Parceiros/Ma-eltrica.png" />
                        </a>
                    </div>
                </div>
                
                <div className="row linha2">
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://www.instagram.com/imperiodosdescartaveisstore/" target="_blank">
                            <img src="/Images/Parceiros/ImperioDescartaveis.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/44EOTkq" target="_blank">
                            <img src="/Images/Parceiros/Issoebom.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/3P9LiVA" target="_blank">
                            <img src="/Images/Parceiros/Gomes-Figueredo.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://api.whatsapp.com/send?phone=5581998753986" target="_blank">
                            <img src="/Images/Parceiros/OGS.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="#">
                            <img src="/Images/Parceiros/Serasa.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://api.whatsapp.com/send?phone=5581997780156" target="_blank">
                            <img src="/Images/Parceiros/Diniz-contabilidade.png" />
                        </a>
                    </div>                    
                </div>
                
                <div className="row linha1">
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="https://api.whatsapp.com/send?phone=5581988823062" target="_blank">
                        <img src="/Images/Parceiros/JH.png" />
                    </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/45QenfI" target="_blank">
                            <img src="/Images/Parceiros/ArouchaGas.png" />
                        </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="https://osayk.com.br/" target="_blank">
                        <img src="/Images/Parceiros/Osayk.png" />
                    </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="https://github.com/" target="_blank">
                        <img src="/Images/Parceiros/GitHub.png" />
                    </a>
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                    <a href="https://aws.amazon.com/pt/" target="_blank">
                        <img src="/Images/Parceiros/AWS.png" />
                    </a>    
                    </div>
                    <div className="col-2 grayscale-image zoom-img">
                        <a href="https://bit.ly/3FzNu4w" target="_blank">
                            <img src="/Images/Parceiros/Fisconet.png" />
                        </a>
                    </div>
                </div>
            </div>
        </div>    
    </section>;
  }

export default Parceiros;