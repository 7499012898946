import React from 'react';
import './fisconet.css';

function Produtosfisconet(){
    return <section id="produtos-fisconet">      
         <div className="container">                
            <div className="row">
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/3Qj0nVo" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/EmpregadoDomestico.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/45OpWUk" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/FisconetFlixDP.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/4704LQ6" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/AnalistaDepartamentoPessoal.png" className="zoom-img" alt="..."/></a></center>
                </div>
            </div>
            <div className="content-fisconet text-center">
			    <iframe width="560" height="315" src="https://www.youtube.com/embed/LXtOHESKcWo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
            <div className="row">
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/3FG1o4Y" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/SimplesNacionalContribuicaoPrevidenciaria.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/3QBCpq4" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/EspecializacaoContabil.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/3tRImpO" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/AnalistaContabil.png" className="zoom-img" alt="..."/></a></center>
                </div>
            <div className="content-fisconet text-center">
			    <iframe width="560" height="315" src="https://www.youtube.com/embed/GW2ypobPec8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>    
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/49auUxs" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/PlanejamentoTributario.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/45P5vXg" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/RotinasTrabalhistas.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/40vfUGJ" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/EspecializacaoTributaria.png" className="zoom-img" alt="..."/></a></center>
                </div>
            <div className="content-fisconet text-center">
			    <iframe width="560" height="315" src="https://www.youtube.com/embed/qVXZmRGJaRo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>    
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/45SKFWO" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/ReclamatoriaTrabalhista.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/3QBXkt9" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/RetencaoPrevidenciaria.png" className="zoom-img" alt="..."/></a></center>
                </div>
                <div className="col-lg-4">
                    <center><a href="https://bit.ly/47dnNCN" target="_blank" rel="nofollow"><img src="/Images/Parceiros/sites/Fisconet/DepartamentoPessoalExtensvo2023.png" className="zoom-img" alt="..."/></a></center>
                </div>
            </div>  
        </div>
                       
    </section>;
  }

export default Produtosfisconet;