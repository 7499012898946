import React, { useState, useEffect } from 'react';

function Menu() {
  const [navbarScrolled, setNavbarScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Define um valor de scroll para a mudança de cor, por exemplo, 50px
      if (window.scrollY > 50) {
        setNavbarScrolled(true);
      } else {
        setNavbarScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Limpa o eventListener quando o componente é desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar fixed-top navbar-expand-md navbar-dark ${navbarScrolled ? 'scrolled' : ''}`}>
      <div className="container-fluid">
        <a className="navbar-brand-expand-lg" href="/#">
          <img src="Images/logo-plataforma-cont-oline.png" alt="" height="54" />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link zoom-img" aria-current="page" href="#inicio">Início</a>
            </li>
            <li className="nav-item">
              <a className="nav-link zoom-img" aria-current="page" href="#beneficios">Benefícios</a>
            </li>
            <li className="nav-item">
              <a className="nav-link zoom-img" aria-current="page" href="#testemunho">Clientes</a>
            </li>
            <li className="nav-item">
              <a className="nav-link zoom-img" aria-current="page" href="#abrir-empresa-gratis">Abrir Empresa Grátis</a>
            </li>
            <li className="nav-item">
              <a className="nav-link zoom-img" aria-current="page" href="#planos-e-precos">Planos</a>
            </li>
            <li className="nav-item zoom-img">
              <a className="nav-link" aria-current="page" href="#contato">Contato</a>
            </li>
          </ul>
        </div>    
      </div>
    </nav>
  );
}

export default Menu;
