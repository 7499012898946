import React from 'react';
import './links-avisos.css';

function Procramacao(){
    return <div>
                   
      
    <section id="aviso-feriado">
      
    <div className="container-aviso-feriado">
        <div className="col-lg-4">
            <img src="/Images/Logo-WCONTEC.P.png"/>
        </div>
		<div className="header-aviso-feriado">
			<h1>Informações importantes sobre os serviços disponíveis</h1>
		</div>
		<div className="content-aviso-feriado">
            <h3>O dia da <a href="https://www.calendarr.com/brasil/proclamacao-da-republica/" role="button" aria-expanded="false" aria-controls="collapseExample" target="_blank">Proclamação da República</a> está se aproximando e trará algumas alterações no horário de funcionamento de nossos serviços:</h3>
            <h4><img src="Images/check.png" />As solicitações de saque realizadas na plataforma em 14/11 serão creditadas em sua conta somente em 18/11.</h4>
            <h5>Bom feriado!</h5>
            <h5>Time Wcontec</h5>
		</div>
		<footer className="social-media">
			<a href="https://www.facebook.com/profile.php?id=100072384950030" target="_blank"><i className="fab fa-facebook-square"></i></a>
			<a href="https://instagram.com/wcontec?r=nametag" target="_blank"><i className="fab fa-instagram-square"></i></a>
			<a href="https://youtube.com/@wcontec2017" target="_blank"><i className="fab fa-youtube-square"></i></a>
			<a href="https://twitter.com/w_contec" target="_blank"><i className="fab fa-twitter-square"></i></a>
			<p>Copyright © 2023 Wcontec. Todos direitos reservados</p>
		</footer>	  
	</div>

    </section>            
  </div> 
  }

export default Procramacao;