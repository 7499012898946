import React from 'react';
import PushMessage from '../PushMessage/pushmessage';
import Menu from './Components/menu';
import Inicio from './Components/inicio';
import Beneficios from './Components/beneficios';
import Testemunho from './Components/testemunho';
import Abrirempresagratis from './Components/abrirempresagratis';
import Precos from './Components/precos';
import Parceiros from './Components/parceiros';
import Contato from './Components/contato';

function Site(){
    return (
        <div>
            <div className="content">
                <PushMessage/>
                <Menu />
                <Inicio />
                <Beneficios />
                <Testemunho />
                <Abrirempresagratis />
                <Precos />
                <Parceiros />
                <Contato />
            </div>
        </div>
    );
}

export default Site;