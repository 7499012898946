import React from 'react';
import './gestao-de-estoque-emissao-nfe.css';

function GestaoNFE(){
    return <div>
                   
      
    <section id="gestaoNFE">
      
    <div className="container-gestaoNFE">
		<div className="header-gestaoNFE">
			<h1>Transforme seu negócio com uma <span className="segundo-h1">gestão de estoque</span> inteligente e ágil: aprenda a <span class="segundo-h1">emitir NF-e</span> e controlar movimentações de forma descomplicada!</h1>
		</div>
		<div className="content-gestaoNFE text-center">
			<iframe width="560" height="315" src="https://www.youtube.com/embed/9pTUws0N3OA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			<a href="https://bit.ly/3gkBvyJ" className="btn btn-primary btn-lg btn-gestaoNFE">Confira aqui mais dicas como esta</a>
		</div>
		<footer className="social-media">
			<a href="https://www.facebook.com/profile.php?id=100072384950030" target="_blank"><i className="fab fa-facebook-square"></i></a>
			<a href="https://instagram.com/wcontec?r=nametag" target="_blank"><i className="fab fa-instagram-square"></i></a>
			<a href="https://youtube.com/@wcontec2017" target="_blank"><i className="fab fa-youtube-square"></i></a>
			<a href="https://twitter.com/w_contec" target="_blank"><i className="fab fa-twitter-square"></i></a>
			<p>Copyright © 2023 Wcontec. Todos direitos reservados</p>
		</footer>	  
	</div>

    </section>            
  </div> 
  }

export default GestaoNFE;