import React from 'react';
import './descadastrese.css';

function Descadastramento(){
    return <div>
                   
      
      <section id="descadastramento">
        
        <div className="container">                
          <div className="row">
            <div className="col-lg-12">
              <center><img src="/Images/Descadrastre-se/descadastramento.png" alt="..."/></center>  
            </div>
          </div>
        </div>
                
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                  <h1>Descadastramento realizado com sucesso</h1> 
                  <h5>Ficamos no aguardo de tê-lo novamente conosco</h5>  
                  <p>A partir de agora você não receberá mais mensagens de noticias e informações por e-mail, mas caso faça o uso da plataforma de gestão financeira e assessoria contábil online continuará recebendo e-mails deste serviço normalmente.</p>
                  <center><a href="/" className="btn btn-outline-primary btn-lg btn-descadastramento">Área do Cliente</a></center>
                </div>
              </div>
            </div>
                
         
      </section>            
    </div> 
  }

export default Descadastramento;