import React from 'react';
import Lottie from 'lottie-react';
import animationData from './lottie-OlhosDesnortiados.json'; // Certifique-se de que a animação tenha fundo transparente
import './notfound.css';

const NotFound = () => {
    return (
      <div>
        <section id="notfound">
          <div>
            {/* Animação Lottie */}
            <Lottie
              animationData={animationData}
              loop={true}
              style={{ height: 150, width: 150, margin: '0 auto' }}
            />
            <p className="text-cinza">Erro 404 - Página não encontrada!</p>
            <center>
              <a href="/" className="btn btn-outline-primary btn-lg btn-notfound">
                Área do Cliente
              </a>
            </center>
          </div>
        </section>
      </div>
    );
  };
  
  export default NotFound;
